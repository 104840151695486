import React, { useEffect, useState } from "react"
import "./Survey.css"
import { AuthContext } from '../App';
import { Button, InputLabel, LinearProgress, Paper } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import axios from 'axios';
import * as signalr from '@microsoft/signalr';
import qs from "qs";
import { toast } from "react-toastify"
import { ISurveyInformationDTO } from "../interfaces/ISurveyInformationDTO";
import { LaptopWindowsOutlined } from "@material-ui/icons";
import { SSL_OP_EPHEMERAL_RSA } from "constants";

export function Survey(props: any) {

    const { state, dispatch } = React.useContext(AuthContext);
    const [ progress, setProgress ] = React.useState<any>(null);
    const [ surveyLink, setSurveyLink ] = React.useState<string>();
    const [ signatoryName, setSignatoryName ] = React.useState<string>();
    const [ signatoryId, setSignatoryId ] = React.useState<number>();
    const [ signatoryGuid, setSignatoryGuid ] = React.useState<number>();
    useEffect(() => {
        let id;
        let guid;
        if (state?.signatoryId && state.signatoryId > 0) {
            id = state.signatoryId; 
            guid = state.guid;
        } else{                
            id = state.user.affiliatedSignatories[0].id;
            guid = state.user.affiliatedSignatories[0].guid;
        }

        if(id !== undefined) setSignatoryId(+id);
        if(guid !== undefined) setSignatoryGuid(id);
         
        axios.get<ISurveyInformationDTO>("/signatorydetail/getsurveyinformation?id=" + guid).then(response => {
            
            if (response === null || response.data === null) {

                if (props.location?.state) {
                    const affiliatedSignatories = state?.user?.affiliatedSignatories;
                    const reportingContributorSignatory = affiliatedSignatories.find((x: { role: number; }) => x.role != 6);
                    if (reportingContributorSignatory) {

                        const reportingContributorSignatoryId = reportingContributorSignatory.id;
                        const reportingContributorSignatoryGuid = reportingContributorSignatory.guid;
                        dispatch({ type: "SET_SIGNATORY_ID", payload: reportingContributorSignatoryId });
                        dispatch({ type: "SET_SIGNATORY_GUID", payload: reportingContributorSignatoryGuid });
                        props.history.push("/survey?id=" + reportingContributorSignatoryId);
                        axios.get<ISurveyInformationDTO>("/signatorydetail/getsurveyinformation?id=" + reportingContributorSignatoryGuid).then(validSetResponse => {
                            setSurveyLink(validSetResponse.data.surveyLink);
                            setSignatoryName(validSetResponse.data.signatoryName);
                        })
                    } else {

                        if (props.location.state?.prevFrom) {

                            toast.error('You are not authorized to access the permissions management application.', {
                                position: "bottom-right",
                                autoClose: 3333,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                });
                                props.history.push({ pathname: '/dataportalv2', state: { from: props.location.pathname } })
                        } else {
                            toast.error('You are not authorized to access the survey for any affiliated signatory.', {
                                position: "bottom-right",
                                autoClose: 3333,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                });
                                setTimeout(() => {
                                window.location.href = 'https://unpri.org';
                                }, 2000);
                        }
                    }
                } else {

                    props.history.goBack()
                }
            } else {
                setSurveyLink(response.data.surveyLink);
                setSignatoryName(response.data.signatoryName);
            }
        })

    }, [state])


    function saveByteArray(reportName: any, byte: any) {
        var blob = new Blob([byte], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    const handleExportSurvey = async () => {

        setProgress(1);
        let connectionId = ""

        let request = {
            signatoryId: signatoryId && signatoryId !== 0 ? signatoryId : state.user.affiliatedSignatories[0].id,
            connectionId: connectionId
        }

        try{
            const connection = new signalr.HubConnectionBuilder()
            .withUrl("/surveyHub")
            .configureLogging(signalr.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

            connection.onclose(async() =>  await connection.start());

            connection.on('progressUpdated', (progress) => {
                setProgress(progress);
            });

            await connection.start();

            if(connection.connectionId){
                request.connectionId = connection.connectionId.toString();
            }
        }
        catch(e){
            console.log(e);
        }


        const prepareSurvey = await axios.post<any>("survey/preexport", request);

        console.log("preexport", prepareSurvey);

        const response = await axios.post<any>("survey/export", request);

        if (response.data == null) {
            setProgress(null);
            toast.error("An error occurred exporting the survey", {
                position: "bottom-right",
                autoClose: 3333,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return;
        }

        saveByteArray("survey.docx", Buffer.from(response.data, 'base64'));
        setProgress(null);
    }

    return (
        <>
            {progress &&                       
                <div style={{ marginLeft: "auto", marginTop: 5, marginBottom: 10, marginRight: "auto", textAlign: "center" }} >Your export is being created, this may take up to 20 minutes. If you do not get a download after this time, please contact <a href="mailto:reporting@unpri.org">reporting@unpri.org</a></div>
            }
            <div className="exportGroup">
                {/*<Button className="exportButton" variant="contained" color="primary" onClick={handleExportSurvey} disabled={progress}>
                    Export Survey
                    </Button>*/}
                { /*progress && 
                    <LinearProgress className="progressBar" variant="determinate" value={progress} />
                */}
                <br/>
                {/* <b>The reporting cycle is closed and access to the reporting tool is not available.</b> */}
                <span style={{color: "#268cc8"}}>The <b>2023 Reporting Tool</b> is currently under construction. In the meantime, please set up your reporting contacts. <br/>
                This can be done from the “USERS / ROLES” section. See our <a href="https://www.unpri.org/download?ac=12447"><b>Permissions System guide</b></a> for more information.</span>
                
            </div>
           
            {/*<div className="userGuide">
                <Button
                    variant="text"
                    color="primary"
                    target="_blank" href="https://www.unpri.org/download?ac=12446&adredir=1"
                    startIcon={<InfoOutlinedIcon />}
                >
                    User Guide
                 </Button>
            </div>*/}
            <h3 className="signatoryTitle">{signatoryName}</h3>
            <div>
                <iframe src={surveyLink} className="iframe">Your browser doesn't support iFrames.</iframe>
            </div>
        </>
    );
}
