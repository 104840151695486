import { Dispatch } from 'react';
import AuthConfig from '../../config/AuthConfig';
import axios from 'axios';

/**
     * Sets the main signatory based on the user's affiliated signatories and email domain name.
     *
     * @param {Object} user - The user object.
     * @param {Function} dispatch - The dispatch function from the AuthContext.
     */
export const setMainSignatoryFromUsernames = (user: any, dispatch: Dispatch<any>) => {
    const affiliatedSignatories = user?.affiliatedSignatories;
    const emailDomainNameRegexp = '(?<=@)[^.]+(?=\\.)'
    const userEmailDomainName = user?.username.match(emailDomainNameRegexp)[0];
    let selectedSignatory = affiliatedSignatories[0];
    affiliatedSignatories.forEach((signatory: any) => {
        let signatoryNameSplit = signatory.name.toLowerCase().split(' ')
        signatoryNameSplit.forEach((word: any) => {
            if (userEmailDomainName.includes(word))
                selectedSignatory = signatory;
        });
    });
    dispatch({ type: "SET_SIGNATORY_ID", payload: selectedSignatory?.id });
    dispatch({ type: "SET_SIGNATORY_GUID", payload: selectedSignatory?.guid });
};

/**
 * Navigates to the third party login page.
 * @param props - The props object containing the history object.
 */
export const navigateToLogin = (props: any) => {
    console.log("Navigating to third party login page");
    const history = props.history.location.state?.from || '/'
    window.location.href = `${AuthConfig.authUrl}/login?retURL=https://${window.location.host}${history}`
};

/**
 * Runs a logout timer that dispatches a "LOGOUT" action after a specified time.
 * @param dispatch - The dispatch function from the Redux store.
 * @param timer - The time (in milliseconds) after which the "LOGOUT" action should be dispatched.
 */
export const runLogoutTimer = (dispatch: Dispatch<any>, timer: number) => {
    setTimeout(() => {
        dispatch({type :"LOGOUT"});
    }, timer);
}



export const getDashToken = async (guid: string) => {
    
    if (guid === null || guid === "") {
        guid = '00000000-0000-0000-0000-000000000000';
    }
    const response = await axios.get<any>("/signatorydetail/GetDashToken?id=" + guid);
    if (response !== null && response.data !== null && response.data !== "") {
        return response.data;
    }
}