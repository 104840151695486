import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Card } from '@material-ui/core';
import { AuthContext } from "../App";
import ReactDOM from "react-dom";
import { useHistory, Redirect } from "react-router-dom";
// @ts-ignore
import { DashApp } from "dash-embedded-component";
import plotlyConfig from '../config/PlotlyConfig';
import { userService } from '../services/userService';
import { getDashToken, runLogoutTimer, setMainSignatoryFromUsernames } from "./utils/loginUtils";


window.React = React;
window.ReactDOM = ReactDOM;
// @ts-ignore
window.PropTypes = PropTypes;

toast.configure();


const useStyles = makeStyles((theme: any) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
}));

export function PlotlyDash(props: any) {

    const classes = useStyles();
    const { state, dispatch } = React.useContext(AuthContext);
    const [dashToken, setDashToken] = React.useState("");
    const [loadDash, setLoadDash] = React.useState(false);
    const history = useHistory();


    // attempt to auto login on page load
    useEffect(() => {
        try {
            userService.autoLogin().then(user => {
                if (user !== null) {
                    // if the user doesn't have the necessary permissions, return error
                    if (!user.authenticated) {
                        dispatch({type: "SET_ERROR_MESSAGE", payload: "Sorry, it seems you don’t have the necessary permissions to access this platform. Please reach out to support@unpri.org for further assistance"})
                        dispatch({ type: "LOGOUT" })
                    } else {
                        // otherwise, attempt to log in with that user
                        dispatch({ type: "LOGIN", payload: user })

                        // set the auto timeout
                        runLogoutTimer(dispatch, 3600000)

                        // get the relevant signatories
                        setMainSignatoryFromUsernames(user, dispatch);
                    }
                }
            }).catch(error => {
                console.error('Error during auto login:', error);
            });
        } catch (error) {
            console.error('Error during auto login:', error);
        }
        }, [])


    // if the guid changes, attempt to get the dash token
    useEffect(() => {
        const fetchData = async () => {
            await getDashToken(state?.guid);
            setLoadDash(false);
            setDashToken(await getDashToken(state.guid));
        };

        fetchData();
    }, [state?.guid]);

    // if the dashtoken changes, reload the dash app
    useEffect(() => {
        if (dashToken.length > 0) {
            setTimeout(() => {
                setLoadDash(true);
            }, 1000);
        }
    }, [dashToken]);


    // set the timeout for the jwt expiration
    useEffect(() => {
        const jtwExpirationTime = 3600000;
        setTimeout(() => {
            // history.push("/transition");
        }, jtwExpirationTime)
    }, [])



    return (
        <div className={classes.root}>
                <Card className={classes.paper} >
                <CardContent>
                        {loadDash &&
                            <DashApp
                        config={{
                                url_base_pathname: plotlyConfig.plotlyUrl,
                                auth_token: dashToken
                                }}
                                />
                        }
                    </CardContent>
                </Card>
                {state?.user && !state?.user?.acceptedTnCs && history.location.state != '/termsandconditions' && <Redirect to='/termsandconditions'></Redirect>}
        </div>
    );
}
