import * as React from 'react'

type Action = {type: 'SEASDASDASASDASDFFILIAASDASDTE', payload: string}
type Dispatch = (action: Action) => void
type State = {currentAffiliate: string}
type CountProviderProps = {children: React.ReactNode}

const AppContext = React.createContext<State | undefined>(undefined)
const AppDispatchContext = React.createContext<Dispatch | undefined>(undefined,)

// deprecated - to be removed

function appReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SEASDASDASASDASDFFILIAASDASDTE': {
      return {currentAffiliate: action.payload}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AppProvider({children}: CountProviderProps) {
  const [state, dispatch] = React.useReducer(appReducer, {currentAffiliate: "1" })
  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  )
}

function useAppState() {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider')
  }
  return context
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }
  return context
}

export {AppProvider, useAppState, useAppDispatch}
