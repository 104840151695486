import React, { useEffect } from 'react';
import { makeStyles, useTheme, lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./Signatories.css"

toast.configure()

function TablePaginationActions(props: any) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event: any) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: any) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: any) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: any) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

interface IEnhancedToolBarProps {
    onSearchTermChanged: (searchTerm: string) => void
}

const EnhancedTableToolbar = (props: IEnhancedToolBarProps) => {
    const classes = useToolbarStyles();
    const { onSearchTermChanged } = props;

    return (
        <Toolbar style={{ backgroundColor: '#07aeef', color: 'white' }}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Signatories
            </Typography>
            <Tooltip title="Search">
                <IconButton aria-label="filter list">
                    <input type="text" style={{ fontSize: 20 }} onChange={e => onSearchTermChanged(e.target.value)} placeholder="  Type here to Search"></input>
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};


// TODO: Move these interfaces out to separate files
interface ISignatory {
    id: number
    guid : string
    name: string
    signatoryType: string
    country: string
    region: string
    signatoryCategory: string
    isSelected: boolean
}

interface ISignatoriesResponseDTO {
    signatories: Array<ISignatory>
    total: number
}

export function Signatories(props: any) {

    const classes = useStyles();
    const [rows, setRows] = React.useState<Array<ISignatory>>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [total, setTotal] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState("")
    const [selectedIndex, setSelectedIndex] = React.useState("*")
    const [category, setCategory] = React.useState("")
    const [selectedItems, setSelectedItems] = React.useState<Array<ISignatory>>(new Array<ISignatory>())

    useEffect(() => {
        axios.get<ISignatoriesResponseDTO>(`/signatoriesdata?pageNumber=0&pageSize=${rowsPerPage}&searchTerm=&indexChar=`).then(x => {
            setTotal(x.data.total)
            setRows(x.data.signatories)
        })
    }, [])

    const handleChangePage = (event: any, newPage: number) => {
        axios.get<ISignatoriesResponseDTO>(`/signatoriesdata?pageNumber=${newPage}&pageSize=${rowsPerPage}&searchTerm=${searchTerm}&indexChar=${selectedIndex}&category=${category}`).then(x => {
            setTotal(x.data.total)
            setRows(rows.concat(x.data.signatories))
            setPage(newPage)
        })
    };

    const handleChangeRowsPerPage = (event: any) => {
        axios.get<ISignatoriesResponseDTO>(`/signatoriesdata?pageNumber=0&pageSize=${event.target.value}&searchTerm=${searchTerm}&indexChar=${selectedIndex}&category=${category}`).then(x => {
            setTotal(x.data.total)
            setRows(rows.concat(x.data.signatories))
            setRowsPerPage(event.target.value)
            setPage(0)
        })
    };

    const handleSearchTermChanged = (searchTerm: string) => {
        if(searchTerm.length > 2) {
            axios.get<ISignatoriesResponseDTO>(`/signatoriesdata?pageNumber=${page}&pageSize=${rowsPerPage}&searchTerm=${searchTerm}&indexChar=${selectedIndex}&category=${category}`).then(x => {
                setTotal(x.data.total)
                setRows(x.data.signatories)
                setPage(0)
                setSearchTerm(searchTerm)
            })
        } else {
            axios.get<ISignatoriesResponseDTO>(`/signatoriesdata?pageNumber=${page}&pageSize=${rowsPerPage}&searchTerm=&indexChar=`).then(x => {
                setTotal(x.data.total)
                setRows(x.data.signatories)
                setPage(0)
                setSearchTerm("")
            })
        }
    };

    const handleIndexChanged = (index: string) => {
        axios.get<ISignatoriesResponseDTO>(`/signatoriesdata?pageNumber=0&pageSize=${rowsPerPage}&searchTerm=${searchTerm}&indexChar=${index}&category=${category}`).then(x => {
            setTotal(x.data.total)
            setRows(x.data.signatories)
            setPage(0)
            setSelectedIndex(index)
        })
    }

    const handleCategoryFilterChanged = (categoryFilter: string) => {
        if(categoryFilter === "All Categories") categoryFilter = ""
        axios.get<ISignatoriesResponseDTO>(`/signatoriesdata?pageNumber=0&pageSize=${rowsPerPage}&searchTerm=${searchTerm}&indexChar=${selectedIndex}&category=${categoryFilter}`).then(x => {
            setTotal(x.data.total)
            setRows(x.data.signatories)
            setPage(0)
            setCategory(categoryFilter)
        })
    }

    const handleRowSelectionChanged = (signatory: ISignatory, value: boolean) => {
        alert('you click on signatory ' + signatory.id)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

    function truncate(str: string, n: number){
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    };

    function handleRowClicked(guid: string, id:string) {
        console.log('GUID CLICKED ON:   ' + guid)
        console.log('ID CLICKED ON:   ' + id)
        // TODO: CHANGE THIS TO PROPS.PUSH
        props.history.push(`/signatory?id=${guid}`)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar onSearchTermChanged={handleSearchTermChanged} />
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableCell>Signatory</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Region</TableCell>
                        <TableCell>
                            <Form.Control as="select" onChange={(e) => handleCategoryFilterChanged(e.target.value)}>
                                <option selected>All Categories</option>
                                <option>Investment Manager</option>
                                <option>Asset Owner</option>
                                <option>Service Provider</option>
                            </Form.Control>
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow style={{ cursor: "pointer" }} hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => handleRowClicked(row.guid, row.id.toString())}>
                                    <TableCell id={row.id.toString()}>
                                        {truncate(row.name, 40)}
                                    </TableCell>
                                    <TableCell>{row.signatoryType}</TableCell>
                                    <TableCell>{row.country}</TableCell>
                                    <TableCell>{row.region}</TableCell>
                                    <TableCell>{row.signatoryCategory}</TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 33 * emptyRows, cursor: "pointer" }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {/* Convert this into a custom control */}
            <ul className="index">
                <li className={selectedIndex === "*" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("*")} href="javascript:void(0)">*</a></li>
                <li className={selectedIndex === "A" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("A")} href="javascript:void(0)">A</a></li>
                <li className={selectedIndex === "B" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("B")} href="javascript:void(0)">B</a></li>
                <li className={selectedIndex === "C" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("C")} href="javascript:void(0)">C</a></li>
                <li className={selectedIndex === "D" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("D")} href="javascript:void(0)">D</a></li>
                <li className={selectedIndex === "E" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("E")} href="javascript:void(0)">E</a></li>
                <li className={selectedIndex === "F" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("F")} href="javascript:void(0)">F</a></li>
                <li className={selectedIndex === "G" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("G")} href="javascript:void(0)">G</a></li>
                <li className={selectedIndex === "H" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("H")} href="javascript:void(0)">H</a></li>
                <li className={selectedIndex === "I" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("I")} href="javascript:void(0)">I</a></li>
                <li className={selectedIndex === "J" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("J")} href="javascript:void(0)">J</a></li>
                <li className={selectedIndex === "K" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("K")} href="javascript:void(0)">K</a></li>
                <li className={selectedIndex === "L" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("L")} href="javascript:void(0)">L</a></li>
                <li className={selectedIndex === "M" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("M")} href="javascript:void(0)">M</a></li>
                <li className={selectedIndex === "N" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("N")} href="javascript:void(0)">N</a></li>
                <li className={selectedIndex === "O" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("O")} href="javascript:void(0)">O</a></li>
                <li className={selectedIndex === "P" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("P")} href="javascript:void(0)">P</a></li>
                <li className={selectedIndex === "Q" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("Q")} href="javascript:void(0)">Q</a></li>
                <li className={selectedIndex === "R" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("R")} href="javascript:void(0)">R</a></li>
                <li className={selectedIndex === "S" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("S")} href="javascript:void(0)">S</a></li>
                <li className={selectedIndex === "T" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("T")} href="javascript:void(0)">T</a></li>
                <li className={selectedIndex === "U" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("U")} href="javascript:void(0)">U</a></li>
                <li className={selectedIndex === "V" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("V")} href="javascript:void(0)">V</a></li>
                <li className={selectedIndex === "W" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("W")} href="javascript:void(0)">W</a></li>
                <li className={selectedIndex === "X" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("X")} href="javascript:void(0)">X</a></li>
                <li className={selectedIndex === "Y" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("Y")} href="javascript:void(0)">Y</a></li>
                <li className={selectedIndex === "Z" ? "indexChar indexSelected" : "indexChar"}><a onClick={() => handleIndexChanged("Z")} href="javascript:void(0)">Z</a></li>
            </ul>
        </div>
    )
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));