import axios from 'axios';
import { IAuthenticationResponseDTO } from '../interfaces/IAuthenticationResponseDTO';


async function login(username: string, password: string): Promise<any> {
    const response = await axios.post<IAuthenticationResponseDTO>("/users/authenticate", {username, password});
    const user = response.data;
    user.authdata = window.btoa(username + ':' + password);
    return user;
}

async function autoLogin(): Promise<any> {
    const response = await axios.post<IAuthenticationResponseDTO>("/users/authenticate", {username: "", password:""});
    const user = response.data;
    if (typeof user != "string" && user !== null) {
        user.authdata = window.btoa("" + ':' + "");
    }
    return user;
}

async function logout(): Promise<any> {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    const response = await axios.post<IAuthenticationResponseDTO>("/users/logout");
    const resetUser = response.data;
    return resetUser;
}

function handleResponse() {
    console.log('working');
}

function getAll() {
    return fetch(`https://localhost:5002/users`).then(handleResponse);
}

export const userService = {
    login,
    logout,
    autoLogin,
    getAll,
};