import React, { useEffect } from 'react';
import { AuthContext } from "../App";
import { useHistory } from "react-router-dom";

export function Home() {
    const { dispatch } = React.useContext(AuthContext);
    const history = useHistory();
    useEffect(() => {
        dispatch({ type: "LOGOUT" })
    }, []);

  history.push('/dataportalv2')
  return (
    <div></div>
  );
}
