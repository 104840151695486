import React, { useEffect, useState } from 'react';

import { useHistory } from "react-router-dom";

export function TransitionPage(props) {

    const history = useHistory();

    useEffect(() => 
    { 
        if (history.location?.state) {
            history.push({
                pathname: history.location.state.from, 
                state: { 
                    from: history.location.state.from, 
                    prevSignatoryId: history.location.state.prevSignatoryId, 
                    newSignatoryId: history.location.state.newSignatoryId 
                }
            })
        } else {
            history.goBack();
        }

    },[]);

    return (<div></div>);
}