import React, { useEffect, useState } from 'react';
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../assets/logo.png'
import Button from 'react-bootstrap/Button'
import { AuthContext } from '../App';
import { useHistory, useLocation } from "react-router-dom";
import { userService } from '../services/userService';
import IAffiliatedSignatoryDTO from "../interfaces/IAffiliatedSignatoryDTO";
import plotlyConfig from '../config/PlotlyConfig';
import ErrorMessage from './ErrorMessage';
import { navigateToLogin } from './utils/loginUtils';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export function NavMenu(props: any) {
  const history = useHistory();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true)
  const { state, dispatch } = React.useContext(AuthContext);
  const [affiliatedSignatoriesDropdown, setAffiliatedSignatoriesDropdown] = useState(<></>);


  const handleAffiliateChanged = (value: string) => {
    const user = state?.user;
    const affiliatedSignatories = user?.affiliatedSignatories;
    const selectedSignatory = affiliatedSignatories.find((x: { id: number; }) => x.id === parseInt(value));
    dispatch({ type: "SET_SIGNATORY_ID", payload: selectedSignatory.id })
    dispatch({ type: "SET_SIGNATORY_GUID", payload: selectedSignatory.guid })
    // history.push({pathname: "/transition", state: { from: history.location.pathname, prevSignatoryId: state?.signatoryId, newSignatoryId: parseInt(value) } });
  }

  useEffect(() => {
    var signatoriesList = [];
    if (location.pathname == '/dataportalv2') {
      signatoriesList = state?.user?.affiliatedSignatories
    } else {
      signatoriesList = state?.user?.affiliatedSignatories.filter(isReportingContributor)
    }

    var dropdownComponent = state?.user && !state?.user.isPriAdmin && signatoriesList.length > 0 ?
      signatoriesList.length == 1 ?
        <span style={{margin:"0 20px 0 0"}}>{signatoriesList.name}</span>
        :
        <select
          style={{ margin: "0 20px 0 0" }}
          onChange={e => handleAffiliateChanged(e.target.value)}
          value={state?.signatoryId}>
            {signatoriesList.map((signatory: any) => (<option value={signatory.id}>{signatory.name}</option>))}
        </select>
      :
        <></>;
    setAffiliatedSignatoriesDropdown(dropdownComponent);
    }, [location, state]);

  async function logoutUser (): Promise<any> {
    dispatch({ type: "LOGOUT" })
    await userService.logout();
    navigateToLogin(props)
  }

  const isAuthenticated = state?.user?.authenticated != null;

  const hasSignatoryAdminPermissions = () => {
    if (state?.user?.affiliatedSignatories?.length > 0 && state?.signatoryId) {
      const selectedSignatory = state.user.affiliatedSignatories.find((x: { id: number; }) => x.id === parseInt(state?.signatoryId));
      return (selectedSignatory?.role === 1 || selectedSignatory?.role === 2);
    }

    if (state?.user?.affiliatedSignatories) {
      const selectedSignatory = state.user.affiliatedSignatories[0];
      return (selectedSignatory?.role === 1 || selectedSignatory?.role === 2);
    }
    return false;
  }

    function isReportingContributor(signatory: IAffiliatedSignatoryDTO) {
      return signatory.role != 6
    }

  return (
    <header style={{marginBottom:20}}>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" dark style={{ padding: 0, backgroundColor:"#268cc8"}}>
        <Container fluid={true} style={{ paddingRight: 0 }}>
          <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem className="navItem">
                <NavLink tag="a" className="navText" href={plotlyConfig.priApplicationURL}>PRI Applications</NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink tag="a" className="navText" href="https://priacademy.org/">PRI Academy</NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Container>
      </Navbar>
        <Container>
          <Row>
            <Col sm="2" md="2" lg="2" xl="2" style={{textAlign:"left"}}><img src={logo} className="logoImage" /></Col>
            <Col sm="10" md="10" lg="10" xl="10" style={{ textAlign: "right", paddingTop:10 }}>
              {isAuthenticated ?
                <>
                  {/* if the  user is authenticated show the logged in menu */}
                  <span style={{ margin: "0 20px 0 0" }}>
                    Hi {(state?.user?.username.split(" ")[0])} {/* first name */}
                  </span>

                  {affiliatedSignatoriesDropdown}

                  {state?.user && !state.user.isPriAdmin && hasSignatoryAdminPermissions() &&
                    <Button as={Link} style={{ marginTop: -5 }} to="/signatory?tab=1" >Manage Permissions</Button>
                  }

                  {state?.user?.isPriAdmin &&
                    <>
                      <Button as={Link} style={{ marginTop: -5 }} to="/signatories">Signatory&nbsp;Search</Button>
                      <Button as={Link} style={{ marginTop: -5 }} to="/priadmins">Manage&nbsp;PRI&nbsp;Admins</Button>
                    </>
                  }

                  <Button style={{ marginTop: -5 }} onClick={logoutUser}>Logout</Button>
                </>
                :
                <>
                  {/* otherwise display the normal flow*/}
                  <Button style={{ marginTop: -5, backgroundColor: 'lightgray', borderColor: 'lightgray', color: 'black' }} href={plotlyConfig.registerUrl}>Register</Button>
                  <Button style={{ marginTop: -5 }}
                    onClick={() => {history.push({pathname: "/login", state: { from: history.location.pathname} });}}>
                      Sign in
                  </Button>
                </>
              }
            </Col>
            {/* if there is an error, display it */}
            {state?.errorMessage &&
              <Col sm="10" md="10" lg="10" xl="10" style={{ textAlign: "left", paddingTop: 10 }}>
                <ErrorMessage message={state?.errorMessage} />
              </Col>
            }
          </Row>
        </Container>
      </header>
    )
}
