import React, { useEffect } from 'react';
import { userService } from '../services/userService';
import { AuthContext } from "../App";
import { useHistory } from "react-router-dom";
import { navigateToLogin, runLogoutTimer, setMainSignatoryFromUsernames } from "./utils/loginUtils";


export function LoginPage(props) {

    const history = useHistory();
    const { dispatch } = React.useContext(AuthContext);

    useEffect(() => {
        // attempt to auto login to the service
        userService.autoLogin().then(user => {
            if (user !== null) {
                // if the user doesn't have the necessary permissions, return error
                if (!user.authenticated) {
                    dispatch({ type: "LOGOUT" })
                    navigateToLogin(props)
                } else {
                    // otherwise, attempt to log in with that user
                    dispatch({ type: "LOGIN", payload: user })

                    // set the auto timeout
                    runLogoutTimer(dispatch, 3600000)
                    // get the relevant signatories
                    setMainSignatoryFromUsernames(user, dispatch);

                    // tidy up history for autologin around signatories
                    if (history.location?.state) {
                        if (history.location?.state?.from == "/login") {
                            props.history.push({ pathname: '/signatory', state: { from: props.location.pathname } })
                        } else {
                            props.history.push({ pathname: history.location.state.from, state: { from: props.location.pathname } })
                        }
                    } else {
                        props.history.push({ pathname: '/signatory', state: { from: props.location.pathname } })
                    }
                }
            } else {
                navigateToLogin()
            }
        }).catch(error => {
            console.error('Error during auto login:', error);
            navigateToLogin(props);
        });;
    }, []);


    return <div>Loading...</div>;

}