import React, { useEffect, useState, useContext } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../assets/logo.png'
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../App';
import { useHistory } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Radio} from '@material-ui/core';

import { Checkbox } from '@material-ui/core';
import { Console } from 'console';

export function RoleCheckBoxes(props: any) {
   
    
    const [reportingChecked, setReportingChecked] = React.useState(false);    
    const [dataChecked, setDataChecked] = React.useState(false);    
    const [adminDashChecked, setAdminChecked] = React.useState(false);    

    const [reportingAndDataChecked, setReportingAndDataChecked] = React.useState(false);
    const [openAdminCheck, setOpenAdminCheck] = React.useState(false);
    const [openRemoveAdminCheck, setopenRemoveAdminCheck] = React.useState(false);
    const [currentRoleChoice, setCurrentRoleChoice] = React.useState("");

    
    const Roles = {
        ReportingContributor:'Reporting Contributor', 
        ReportingAndDataPortalContributor:'Reporting and Data Portal Contributor', 
        Admin: 'Admin', 
        DataPortalContributor: "Data Portal Contributor"};
        
    useEffect(() => {
    switch (props.row.role) {
                case Roles.ReportingContributor:
                    setReportingChecked(true);
                    break;
                case Roles.ReportingAndDataPortalContributor:

                    setReportingAndDataChecked(true);
                    break;
                case Roles.DataPortalContributor:

                    setDataChecked(true);
                    break;
                case Roles.Admin:
                    setAdminChecked(true);
                    break;
            default:
                break;
        
    }

    }, [])


    const handleCloseAdminCheck = (e: any) => {
        setOpenAdminCheck(false);
        setopenRemoveAdminCheck(false)
    }

    const handleChange = (event: any, choice: string, doAdminCheck: boolean) => {
        event.stopPropagation();




        if (choice === Roles.Admin  && doAdminCheck)  {
            
            setCurrentRoleChoice(choice);
            setOpenAdminCheck(true);
            return;
        }
        
        else if (props.row.role == Roles.Admin && currentRoleChoice == Roles.Admin || props.row.role == Roles.Admin && currentRoleChoice == ""){
            setCurrentRoleChoice(choice);
            setopenRemoveAdminCheck(true);
            return;
        }
            
        switch (choice) {
            case Roles.ReportingContributor:
                    setDataChecked(false);
                    setReportingAndDataChecked(false);
                    setAdminChecked(false);
                    setReportingChecked(true);
                    props.handleRoleChanged( Roles.ReportingContributor, props.row);
                    break;
                case Roles.DataPortalContributor:
                    setReportingAndDataChecked(false);
                    setAdminChecked(false);
                    setReportingChecked(false);
                    setDataChecked(true);
                    props.handleRoleChanged(Roles.DataPortalContributor, props.row);
                break;
            case Roles.ReportingAndDataPortalContributor:
                    setAdminChecked(false);
                    setReportingChecked(false);
                    setDataChecked(false);
                    setReportingAndDataChecked(true);
                    props.handleRoleChanged(Roles.ReportingAndDataPortalContributor, props.row);
                break;
            case Roles.Admin:

                    setReportingChecked(false);
                    setDataChecked(false);
                    setReportingAndDataChecked(false);
                    setAdminChecked( true);
                    props.handleRoleChanged(Roles.Admin,  props.row);
                    break;

        
            default:
                break;
        }
        
      };

      const handleClick = (event: any) => {
          event.stopPropagation();
        };

    function MakeAdmin(e: any) {
        handleChange(e, currentRoleChoice, false);
        setOpenAdminCheck(false);
        //handleCloseAdminCheck(e);
    }

    function RemoveAdmin(e: any) {
        handleChange(e, currentRoleChoice, false);
        setopenRemoveAdminCheck(false);
        //handleCloseAdminCheck(e);
    }
  
  return (
      <>
        

          <FormControlLabel
              value="Reporting Contributor"

              control={<Radio checked={reportingChecked} onChange={(e) => handleChange(e, Roles.ReportingContributor, props.adminCheck)} onClick={handleClick} />}
              label="Reporting Contributor"
          />
          
          <FormControlLabel
              value="Data Portal Contributor"

              control={<Radio checked={dataChecked} onChange={(e) => handleChange(e, Roles.DataPortalContributor, props.adminCheck)} onClick={handleClick} />}
              label="Data Portal Contributor"
          />
            <FormControlLabel
              value="Reporting and Data Portal Contributor"
              control={<Radio checked={reportingAndDataChecked} onChange={(e) => handleChange(e, Roles.ReportingAndDataPortalContributor, props.adminCheck)} onClick={handleClick} />}
              label="Reporting and Data Portal Contributor"
              />

          {props.signatory && props.signatory.permittedAssignableRoles.includes(Roles.Admin) &&

              <FormControlLabel
                  value="Admin"

                  control={<Radio checked={adminDashChecked} onChange={(e) => handleChange(e, Roles.Admin, props.adminCheck)} onClick={handleClick} />}
                  label="Admin"
              />
          }

          {/* {props.signatory && props.signatory.permittedAssignableRoles.includes(Roles.MainAdmin) &&

          <FormControlLabel
              value="Main Admin"
              control={<Radio checked={mainAdminChecked} onChange={(e) => handleChange(e, Roles.MainAdmin, props.adminCheck)} onClick={handleClick} />}
              label="Main Admin"
              />

            
          } */}


          <Dialog open={openAdminCheck} onClose={handleCloseAdminCheck} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Admin User</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      By making this user an admin they will have access to all areas of the application including managing permissions. 
                      Do you want to proceed? 
                </DialogContentText>

              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCloseAdminCheck} color="primary">
                      Cancel
                </Button>
                  <Button onClick={MakeAdmin} color="primary">
                      Make Admin
                </Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openRemoveAdminCheck} onClose={handleCloseAdminCheck} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Remove Admin User</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                     Are you sure you want remove this user from being admin? User will lose access to certain areas of the app.
                     Do you want to proceed? 
                </DialogContentText>

              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCloseAdminCheck} color="primary">
                      Cancel
                </Button>
                  <Button onClick={RemoveAdmin} color="primary">
                      Remove Admin
                </Button>
              </DialogActions>
          </Dialog>
    </>)
}
