import React from 'react';
import { useClearCache } from 'react-clear-cache';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Counter } from './components/Counter';
import { Survey } from './components/Survey';
import { PrivateRoute } from './components';
import { LoginPage } from './components/LoginPage';
import { PlotlyDash } from './components/PlotlyDash';
import { PRIAdmin } from './components/PRIAdmin';
import './custom.css'
import { Signatories } from './components/Signatories';
import { TransitionPage } from './components/TransitionPage';
import { TermsAndConditions } from './components/TermsAndConditions';
import { Signatory } from './components/Signatory';
import { AccessRequests } from './components/AccessRequests';
import { DataSync } from './components/DataSync';
import { AppProvider } from './contexts/appContext'
import { SnackbarProvider } from 'notistack';
import axios from "axios";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Cookies from "js-cookie";

export const AuthContext = React.createContext();

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if(token)
    config.headers = { 'Authorization': 'Basic ' + token }
  return config;
});

axios.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    toast.error('You are not authorized to access this resource.', {
      position: "bottom-right",
      autoClose: 3333,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    setTimeout(() => {
      window.location.href = "/";
    }, 4000)
  }
  else if(error.response.status === 403){
    toast.error('You are not authorized to perform this action.', {
      position: "bottom-right",
      autoClose: 3333,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  return Promise.reject(error)
})

const initialState = {
    isAuthenticated: false,
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
    token: null,
    signatoryId: localStorage.getItem("signatoryId"),
    guid : localStorage.getItem("guid"),
    errorMessage: null
};



const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      if (action.payload) {
        localStorage.clear();
        localStorage.setItem("user", JSON.stringify(action.payload));
        localStorage.setItem("token", JSON.stringify(action.payload.authdata));
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload,
          token: action.payload.authdata,
          errorMessage: null
        };
      } else {
        console.error("Invalid payload");
        break;
      };
    case "LOGOUT":
      localStorage.clear();
      Cookies.set("ADFEAuthCookie", "", {expires: -1, path: ''});
      return {
        ...state,
        guid: null,
        signatoryId: null,
        isAuthenticated: false,
        user: null
      };
    case "SET_SIGNATORY_ID":
      localStorage.setItem("signatoryId", action.payload)
      return {
        ...state,
        signatoryId: action.payload
      };
    case "SET_SIGNATORY_GUID":
      localStorage.setItem("guid", action.payload)
      return {
        ...state,
        guid: action.payload
      };
    case "SET_USER_ACCEPTED_TNCS":
      localStorage.setItem("user", JSON.stringify(action.payload))
      return {
        ...state,
        user: action.payload
      };
    case "SET_ERROR_MESSAGE":
      console.error(action.payload)
      return {
        ...state,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  return (
      <AppProvider>
      {!isLatestVersion && (
          <p>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                emptyCacheStorage();
              }}
            >
              Update version
            </a>
          </p>
        )}
      <AuthContext.Provider value={{ state, dispatch }}>
        <SnackbarProvider maxSnack={3}>
          <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/counter' component={Counter} />
            <PrivateRoute path="/survey" component={Survey} />
            <PrivateRoute exact path='/signatories' component={Signatories} />
            <PrivateRoute path='/accessrequests' component={AccessRequests} />
            <PrivateRoute path='/datasync' component={DataSync} />
            <Route path="/login" component={LoginPage} />
            <Route path="/transition" component={TransitionPage} />
            <Route path="/dataportalv2">
                          <PlotlyDash />
            </Route>
            <PrivateRoute path="/signatory" component={Signatory} />
            <PrivateRoute path="/priadmins" component={PRIAdmin} />
            <Route path="/termsandconditions" component={TermsAndConditions} />
          </Layout>
        </SnackbarProvider>
      </AuthContext.Provider>
    </AppProvider>
  )
}
