import React, { useEffect } from 'react';
import './NavMenu.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button} from '@material-ui/core';

import { Checkbox } from '@material-ui/core';

export function PriAdminCheckBox(props: any) {

    const [priAdminChecked, setPriAdminChecked] = React.useState(false);
    const [openPriAdminGrantCheck, setOpenPriAdminGrantCheck] = React.useState(false);
    const [openPriAdminRevokeCheck, setOpenPriAdminRevokeCheck] = React.useState(false);

    const [currentPriAdminChoice, setCurrentPriAdminChoice] = React.useState(true);
        
    useEffect(() => {
        if (props.row.isPriAdmin) {
            setPriAdminChecked(true);        
        }
        else {
            setPriAdminChecked(false)
        }
    }, [])

    const handleChange = (event: any, choice: boolean, doPriAdminCheck: boolean) => {
        event.stopPropagation();

        if (choice && doPriAdminCheck) {
            setCurrentPriAdminChoice(choice)
            setOpenPriAdminGrantCheck(true);
            return;
        }
        else if (!choice && doPriAdminCheck) {
            setCurrentPriAdminChoice(choice)
            setOpenPriAdminRevokeCheck(true);
        }
        else if (choice) {
            setPriAdminChecked(true);
            props.handlePriAdminChange(true, props.row)
        }
        else {
            setPriAdminChecked(false);
            props.handlePriAdminChange(false, props.row)
        }
    
    };

    const handleClosePriAdminGrantCheck = (e: any) => {
        setOpenPriAdminGrantCheck(false);
    };

    const handleClosePriAdminRevokeCheck = (e: any) => {
        setOpenPriAdminRevokeCheck(false);
    };

    const handleClick = (event: any) => {
        event.stopPropagation();
    };
  
  return (
      <>


          <FormControlLabel
              value="PriAdmin"
              control={<Checkbox checked={priAdminChecked} onChange={(e) => 
                {
                    if (priAdminChecked) {
                        handleChange(e, false, props.adminCheck)
                    } else {
                        handleChange(e, true, props.adminCheck)
                    } 
                }
            } onClick={handleClick} />}
              label="PRI Admin"
          />
          


          <Dialog open={openPriAdminGrantCheck} onClose={handleClosePriAdminGrantCheck} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">PRI Admin User</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      By making this user a PRI Admin they will have access to all signatory's permission management pages, surveys and dashboards. 
                      Do you want to proceed? 
                </DialogContentText>

              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClosePriAdminGrantCheck} color="primary">
                      Cancel
                </Button>
                  <Button onClick={MakePriAdmin} color="primary">
                      Make PRI Admin
                </Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openPriAdminRevokeCheck} onClose={handleClosePriAdminRevokeCheck} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">PRI Admin User</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      By revoking this user's PRI Admin permissions they will lose access to all signatory's permission management pages, surveys and dashboards. 
                      Do you want to proceed? 
                </DialogContentText>

              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClosePriAdminRevokeCheck} color="primary">
                      Cancel
                </Button>
                  <Button onClick={RevokePriAdmin} color="primary">
                      Revoke PRI Admin
                </Button>
              </DialogActions>
          </Dialog>
    </>)


    function MakePriAdmin(e: any) {
        handleChange(e, currentPriAdminChoice, false);
        handleClosePriAdminGrantCheck(e);
    }

    function RevokePriAdmin(e: any) {
        handleChange(e, currentPriAdminChoice, false);
        handleClosePriAdminRevokeCheck(e);
    }
}
